<template>
      
  <div class="all">
    <div class="banner">
      <img :src="banners" alt="" />
    </div>
    <div class="list">

      <div class="qiehuan">
        <!-- <span :class="actives == item.id ? 'active' : 'noactive'" @click="active(item)" v-for="(item,key) in catelist" :key="key">{{item.cate_name}}</span> -->
        <span :class="actives == item.id ? 'active' : 'noactive'" @click="active(item)" v-for="(item,key) in catelist" :key="key">{{item.cate_name}}</span>
      </div>
        <div class="options">
      <div class="option">

          <input type="text" v-model="Sreach" placeholder="藏品名称">
          <img src="../assets/3DDetails/search.png" alt="" class="btn" @click="sreachdia">
        </div>
      </div>
      <!-- <div class="sreachBtn" @click="sreachdia">搜索</div> -->
    </div>
    <div class="listCate" v-if="list.length != 0">
      <div @mousedown.stop="an" @mouseup.up="number_skip(item.id)" v-for="(item,key) in list" :key="key"  class="ImglistCate">
        <div :class="(key+1) % 5 == 1 ? 'CateChildrenRight' : ''" class="CateChildren" >
          <div class="newtwo">
 <div class="new">
                  <img :src="item.img" alt="" />
                  <div class="cateBorder"></div>
          </div>
          </div>
         
          
                  <div class="title">
                    {{item.name}}
                  </div>
                  <div class="details" v-html="item.des"></div>
        </div>
        
      </div>
    </div>
    <div class="listCate fontsizesix"  v-else>
        暂无匹配项~
    </div>
    <div class="page"  v-if="pageshow">
      
      
      <!-- <Pagetion  :total="activs" :pageSize="total" @ClickChange="ClickChange"></Pagetion> -->
      <Pagetion  :total="total" :pageSize="pageSize" @ClickChange="ClickChange"></Pagetion>
    </div>
        <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer";
import { fetch } from '../api/https';
import Pagetion from '../components/pagetion/pagetion.vue';
export default {
  data() {
    return {
      actives: 1,
      total: 0,
      list:[],
      Sreach:'',
      catelist:[],
      page:1,
      pageSize:15,
      banners:'',
      pageshow:true
    };
  },
  components: {
    Footer,Pagetion
  },
    Pagetionted() {},
  mounted() {
      this.axio()
      
  },
  methods: {
    an(){ //鼠标按下
       this.timeLength = new Date().getTime()
    },
      axio(){
            this.catelist = ''
            //  let params = {page:this.page,pagesize:this.pageSize}
            
          fetch('/collect/cate').then((res) => {
          this.catelist = res.data
          var obj = {
            cate_name:'全部',
            id:100,
          }
          this.catelist.unshift(obj)
                this.active(this.catelist[0])
            })
            fetch('/getmenu')
            .then(res => {
              this.banners = res.data[4].child[0].menu_img;
              document.title = res.data[4].child[0].menu_seo_title
            })
            .catch(err => {});
      },
      sreachdia(){
          fetch('collect/index',{type:1,name:this.Sreach}).then((res) => {
              this.list = res.data.data
                // this.activs = res.data.total_count
                this.total = res.data.total_count
          })
      },
    active(value) {     
      this.actives = value.id;
       this.pageshow = false;//让分页隐藏
      this.$nextTick(() => {//重新渲染分页
       this.pageshow = true;
      })
    //   if (value == 1) {
    //     this.actives = "石器"
    //   } else if (value == 2) {
    //     this.actives = "青铜器"
    //   } else if (value == 3) {
    //     this.actives = "陶瓷"
    //   } else if (value == 4) {
    //     this.actives = "金银器"
    //   }
    if(this.actives == 100){
      fetch('collect/index',{type:1,cate_id:'',page:this.page,pagesize:this.pageSize}).then((res) => {
        this.list = res.data.data
        // this.activs = res.data.total_count
        this.total = res.data.total_count
    }).catch((err) => {
        
    });
    }else{
      fetch('collect/index',{type:1,cate_id:this.actives,pagesize:this.pageSize}).then((res) => {
              this.list = res.data.data
              // this.activs = res.data.total_count
              this.total = res.data.total_count
          }).catch((err) => {
              
          });
        
    }
    
      
    },
    ClickChange(val) {
      this.page = val;
      // this.axio();
      let params = {
        type:1,
        cate_id:this.actives,
        pagesize:this.pageSize,
        page:this.page
      }
     if(this.actives == 100){
        params.cate_id = ''
      }
      fetch('collect/index',params).then((res) => {
              this.list = res.data.data
              this.total = res.data.total_count
          }).catch((err) => {
              
          });
    },
    number_skip(id){
      this.oldtime = new Date().getTime()
       if((this.oldtime-this.timeLength) < 130){
        this.$router.push(`/digitDetails?id=${id}`)
       }
    }
  },
};
</script>

<style scoped>
.Scales{
  transform: scaleX(0.90);
}
.all {
  width: 100%;
  height: 100%;
}
.banner {
  height: 600px;
  width: 100%;
}
.banner img {
  height: 100%;
  width: 100%;
}
.list {
  height: 39px;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 53px;
  margin-bottom: 61px;
}
.cate {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #bd955d;
  margin-right: 30px;
  line-height: 39px;
  width: 40px;
  height: 39px;
  float: left;
}
.qiehuan {
  float: left;
  /* height: 39px; */
  width: 990px;
  margin-right: 65px;
  color: #bd955d;
}
.active {
  color: white;
  border: none;
  background: url("../assets/img/book/checked.png") no-repeat;
  background-size: 105px 41.7px;
  color: white;

}
.noactive {
  background: url("../assets/img/book/uncheck.png") no-repeat;
  background-size: 105px 41.7px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.qiehuan span {
  box-sizing: border-box;
  display: block;
  float: left;
  width: 105px;
  height: 41.7px;
  border-radius: 5px;
  text-align: center;
  line-height: 41.7px;
  margin-right: 11px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 13px;
}
.btn{
  position:absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
}

.option {
  
  position: relative;
  width: 341px;
    float: right;
  height: 42px;
  background: url('../assets/museumDetails/searchBox.png');
  background-size: 341px 42px;
  /* float: left; */
  /* font-size: 18px; */
  font-family: PingFang SC;
  font-weight: 500;
  /* color: #bd955d; */
  line-height: 39px;
  padding-left: 18px;
  padding-right: 14px;
  box-sizing: border-box;
  padding-top: 0;
}
.option input{
    position: absolute;
    top: 6px;
    border: none;
    width: 267px;
    outline: none;
    height: 30px;
  width: 80% !important;
    font-size: 13px;
    /* margin-top: px; */
}
.option input::-webkit-input-placeholder{
font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 300;
color: #B2B2B2;
}
select {
  border: none;
}
.list{
  position: relative;
  max-width: 1400px;
  min-width: 1366px;
}
.listCate {
  min-width: 1366px;
  max-width: 1400px;
  min-height: 392px;
  clear: both;
  padding-left: -41px;
  margin: 0 auto;
}
.fontsizesix{
  font-size: 16px;
}
.CateChildren {
  width: 245px;
  height: 392px;
  margin-left: 43px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  float: left;
  position: relative;
  overflow: hidden;
}
.CateChildren img {

}
.newtwo{
  overflow: hidden;
  width: 245px;
  height: 306px;
}
.new{
  width: 245px;
  cursor: pointer;
  height: 306px;
  transition: 1s;
}
.new img{
  width: 245px;
  height: 306px;
}
.new:hover{
  transform: scale(1.1);
}
.cateBorder {
  width: 233px;
  height: 292px;
  border: 1px solid #bd955d;
  position: absolute;
  left: 6px;
  top: 6px;
  cursor: pointer;

}
>>>.CateChildrenRight{
  margin-left: 0;
}
.fen {
  width: 0px;
  height: 10px;
  border: 1px solid #bd955d;
  display: inline-block;
  margin-top: 3px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 16px;
  cursor: pointer;
}
.details {
  width: 206px;
  height: 17px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 21px;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.page {
  padding-top: 86px;
  /* min-width: 1366px; */
  /* max-width: 1400px; */
  width:100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 71px;
}
>>> .btn-prev {
  min-width: 74px;
  height: 40px;
}
>>> .number {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
.el-pager >>> li {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>> .btn-next {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}

>>> .more {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>> .el-pager .more::before {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>> .el-pagination__total {
  /* margin-top: 6px; */
  margin-left: 5px;
  /* font-size: 15px; */
  font-weight: 600;
    transform: scale(1.19);

}
@media screen and (max-width: 1200px){
  
  .CateChildren{
    margin-left: 28px;

  }
  .list{
  width: 1366px;
  padding: 0 20px;
}
}
@media screen and (max-width: 1400px){
   .list{
  width: 1366px;
  padding: 0 16px;
  box-sizing: border-box;
}

  .CateChildren{
    margin-left: 25px;
  }
}
</style>